import Vue from 'vue'
import Router from 'vue-router'

// import store from '@/store'
import plugins from '@/plugins'

Vue.use(Router)

const defaultRoutes = [
  // Touchscreen Views
  // {
  //   path: '/',
  //   name: 'index',
  //   component: () => import('@/views/touch/Home.vue'),
  // },
  // {
  //   path: '/archive',
  //   name: 'archive',
  //   component: () => import('@/views/touch/Archive.vue'),
  // },
  // {
  //   path: '/virtual-tours',
  //   name: 'virtual-tours',
  //   component: () => import('@/views/touch/VirtualTours.vue'),
  // },
  // {
  //   path: '/drone-gallery',
  //   name: 'drone-gallery',
  //   component: () => import('@/views/touch/DroneGallery.vue'),
  // },
  // {
  //   path: '/flipbooks-gallery',
  //   name: 'flipbooks-gallery',
  //   component: () => import('@/views/touch/FlipbooksGallery.vue'),
  // },
  // {
  //   path: '/video-gallery',
  //   name: 'video-gallery',
  //   component: () => import('@/views/touch/VideoGallery.vue'),
  // },
  // {
  //   path: '/explore-3d',
  //   name: 'explore-3d',
  //   component: () => import('@/views/touch/ThreeDGallery.vue'),
  // },
  // {
  //   path: '/webmap',
  //   name: 'webmap',
  //   component: () => import('@/views/touch/WebMap.vue'),
  // },
  // {
  //   path: '/ar',
  //   name: 'ar',
  //   component: () => import('@/views/touch/ARView.vue'),
  // },
  // {
  //   path: '/card/:id',
  //   name: 'card',
  //   component: () => import('@/views/touch/SingleCard.vue'),
  // },
  // {
  //   path: '/ar-test',
  //   name: 'ar-test',
  //   component: () => import('@/views/touch/ARToolkitView.vue'),
  // }
]

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_PATH || '/',
  linkActiveClass: "",
  routes: [...defaultRoutes, ...plugins.routes]
})
