module.exports = {
  host: process.env.HOST || 'localhost',
  open: true,
  port: process.env.PORT || 8081,
  apiRoot: process.env.VUE_APP_API_ROOT || 'http://localhost:8001',
  project: {
    title: 'ReMap',
    archive: 'REMAP',
    titleLogo: true,
    logoSize: 'normal', // 'normal' | 'wide
    subtitle: `Mapping time, Mapping space`,
    subtitleSize: 'sm',
    font: {
      title: 'font-montserrat',
      body: 'font-montserrat'
    },
    style: {
      background: 'bg-black',
      homeText: 'text-white',
      text: 'text-white',
      color: '#fff'
    },
    credits: `CreditsRemap`,
    footerTitle: 'Tiles of Time Exhibition',
    copyright: 'LabGEO @ 2025',
    menu: {
      title: '<span class="italic font-light">Re</span>Map Menu',
      subtitle: '<f-icon icon="fingerprint"></f-icon> Touch tiles to explore touchscreen',
      items: [
        { title: 'ReMap', link: '/', name: 'index', image: require("@/assets/logo.svg"), description: 'Return to the Homepage of ReMap WebApp where you can find each content. Explore all the section of touchscreen to Madaba Library WebApp. The place where to start your exploration.', view: 'Home' },
        { title: 'WebMap', link: '/webmap', name: 'webmap', color: 'bg-jordan-brown', image: require("@/assets/images/webmap-banner.jpg"), description: 'The Web Map lead you through the today geographical representation of each single place represented in Madaba Mosaic Map. In this section you can explore the census places distinguished by data source.', view: 'WebMap' },
        { title: 'Digital Archive', link: '/archive', name: 'archive', color: 'bg-mosaic-earth', image: require("@/assets/images/archive-banner.jpg"), description: 'The digital archive allows to explore the Madaba Library repository, enriched by the data made available by the project, filtering each card by region and area and exploring each media e location attached.', view: 'Archive' },
        { title: 'Virtual Tours', link: '/virtual-tours', name: 'virtual-tours', color: 'bg-stone-violet', image: require("@/assets/images/virtual-tours-banner.jpg"), description: 'Virtual tours have been created for fifteen sites of great archaeological interest from the north to the south of Jordan and allow 360° exploration of the sites, adding information of each place. Explore tours through this section.', view: 'VirtualTours' },
        { title: 'Flipbooks Gallery', link: '/flipbooks-gallery', name: 'flipbooks-gallery', color: 'bg-rose-dark',  image: require("@/assets/images/arab-tribes-banner.jpg"), description: 'The Madaba Library include various repository media. In this section you can find all the flipbooks of diaries digitized for the ReMap project. Tap this panel to browse all digitized books.', view: 'FlipbooksGallery' },
      ],
    },
    views: {
      index: {
        explain: 'Explore Tiles of Time touching the panels',
        panels: [
          { title: 'WebMap', link: '/webmap', color: 'bg-jordan-brown', image: require("@/assets/images/webmap-banner.jpg"), description: 'The Web Map lead you through the today geographical representation of each single place represented in Madaba Mosaic Map. In this section you can explore the census places distinguished by data source.'},
          { title: 'Digital Archive', link: '/archive', color: 'bg-mosaic-earth', image: require("@/assets/images/archive-banner.jpg"), description: 'The digital archive allows to explore the Madaba Library repository, enriched by the data made available by the project, filtering each card by region and area and exploring each media e location attached.' },
          { title: 'Virtual Tours', link: '/virtual-tours', color: 'bg-stone-violet', image: require("@/assets/images/virtual-tours-banner.jpg"),  description: 'Virtual tours have been created for fifteen sites of great archaeological interest from the north to the south of Jordan and allow 360° exploration of the sites, adding information of each place. Explore tours through this section.' },
          { title: 'Flipbooks Gallery', link: '/flipbooks-gallery', color: 'bg-rose-dark', image: require("@/assets/images/arab-tribes-banner.jpg"), description: 'The Madaba Library include various repository media. In this section you can find all the flipbooks of diaries digitized for the ReMap project. Tap this panel to browse all digitized books.'},
        ]
      },
      'flipbooks-gallery': {
        title: `Flipbooks Gallery`,
        subtitle: '',
        contents: [
          {link: 'https://www.observo360.com/remap-flipbooks/arconati-atlante/', description: 'Arconati Visconti - Atlante per  servire al diario di viaggio in Arabia Petrea', banner: 'arconati-atlante-banner.jpg'},
          {link: 'https://www.observo360.com/remap-flipbooks/arconati-diario/', description: 'Arconati Visconti - Diario di un viaggio in Arabia Petrea', banner: 'arconati-diario-banner.jpg'},
          {link: 'https://www.observo360.com/remap-flipbooks/mukaddasi-syria/', description: 'Mukkadasi - Description of Syria including Palestine', banner: 'mukkadasi-banner.jpg'},
          {link: 'https://www.observo360.com/remap-flipbooks/arab-tribes/', description: 'The Arab Tribes', banner: 'arab-tribes-banner.jpg'}     
        ]
      },
      'virtual-tours': {
        title: 'Virtual Tours',
        subtitle: ``,
        link: 'http://www.observo360.com/remap',
        background: require("@/assets/images/virtual-tours-banner.jpg")
      },
      'acque-filmati': {
        title: 'Acque - Filmati',
        subtitle: `Nel mondo, l'Italia è il Paese con il più alto numero e concentrazione di siti UNESCO e la Toscana ne ospita otto, esprimendo un territorio permeato di storia, archeologia, arte e tradizioni. Per comprendere appieno questi luoghi, preservarli e promuoverli, è necessario mettere in atto attività educative che li valorizzino in accordo a queste dimensioni culturali.`,
        contents: [
          {content: '-Ri2DOnbaoo', description: 'Description of the following video 1', banner: 'video-banner.jpg'},
          {content: '-Ri2DOnbaoo', description: 'Description of the following ideo 2', banner: 'video-banner.jpg'},
          {content: '-Ri2DOnbaoo', description: 'Description of the following ideo 3', banner: 'video-banner.jpg'},
          {content: '-Ri2DOnbaoo', description: 'Description of the following ideo 4', banner: 'video-banner.jpg'},
          {content: '-Ri2DOnbaoo', description: 'Description of the following ideo 5', banner: 'video-banner.jpg'},
          {content: '-Ri2DOnbaoo', description: 'Description of the following ideo 6', banner: 'video-banner.jpg'},
          {content: '-Ri2DOnbaoo', description: 'Description of the following video 7', banner: 'video-banner.jpg'}
        ]
      },
      webmap: {
        title: 'WebMap',
        explain: '',
        mapConfig: {
          zoom: 8
        },
        wmsType: 'standard', // 'esri'
        wms: []
      }
    }
  }
}