<template>
  <div id="app">
    <div :class="config.style.background+' '+config.font.body" v-if="isAuthenticated">
      <router-view :key="$route.fullPath" />
      <modals-container />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'

import siteConfig from '@/config'

import auth from '@/services/auth'
import Api from '@/components/Api'

export default {
  name: 'App',
  metaInfo: {
    title: 'App',
    titleTemplate: '%s | '+ (process.env.VUE_APP_NAME || 'ReSole')
  },
  mixins: [
    Api
  ],
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user',
      locale: 'app/locale',
      showUserMenu: 'app/showUserMenu'
    }),
  },
  data: () => ({
    show: true,
    config: siteConfig.project,
    views: {
      "Home": () => import("@/views/touch/Home.vue"),
      "Archive": () => import("@/views/touch/Archive.vue"),
      "FlipbooksGallery": () => import("@/views/touch/FlipbooksGallery.vue"),
      "VirtualTours": () => import("@/views/touch/VirtualTours.vue"),
      "DroneGallery": () => import("@/views/touch/DroneGallery.vue"),
      "VideoGallery": () => import("@/views/touch/VideoGallery.vue"),
      "WebMap": () => import("@/views/touch/WebMap.vue"),
      "Panels": () => import("@/views/touch/Panels.vue"),
      "Carousel": () => import("@/views/touch/Carousel.vue"),
    }
  }),
  async created() {
    const vm = this
    vm.loading = true
    let menuItems = this.config.menu.subItems && this.config.menu.subItems.length > 0 ? [...this.config.menu.items, ...this.config.menu.subItems] : this.config.menu.items
    menuItems.forEach(item => {
      this.$router.addRoute({path: item.link, name: item.name, component: this.views[item.view] })
    })
    if(!vm.isAuthenticated){
      try {
        if(process.env.VUE_APP_API_USERNAME && process.env.VUE_APP_API_PASSWORD){
          await auth.login({
            email: process.env.VUE_APP_API_USERNAME, 
            password: process.env.VUE_APP_API_PASSWORD 
          }).then(response => {
            console.log('[login response]', response)
            if(response.success) {
              //vm.$router.push({name: 'home'})
            }else{
              vm.error = response.message || response.data
              vm.status = response.data.status || null
              vm.showError = true
            }
          })
        } else {
          alert('NO API CREDENTIALS')
        }
      } catch (error){
        vm.error = error
        vm.showError = true
        vm.submitting = false
      }
    }
  },
  beforeMount() {
    
  },
  methods: {
    hideUserMenu(){
      this.$store.dispatch(`app/showUserMenu`, {showUserMenu: false})
    },
    showNav(show){
      this.show = show
    }
  },
}
</script>